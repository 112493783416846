import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          I installed react using Node.js. I first installed Node.js and then ran the command npx create-react-app my-app
          After installing the file, I ran the commands cd my-app and npm start
        </p>
        
        <p>
         A difficulty I encountered when building this page is I didn't know which file to edit.
         
         Additionally, I installed the wrong node version and had to reinstall node 
        </p>
      </header>
    </div>
  );
}

export default App;
